<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/iframe/Basket.vue'
import SidePanelHeader from '../../components/iframe/SidePanelHeader.vue';
import basketMixin from '../../mixins/basket'
import merchantMixin from '@/mixins/merchant'
import iframeMixin from '@/mixins/iframe'
import basketService from '@/services/basket'
import Btn from '@/components/iframe/Button.vue'

export default {

    mixins: [basketMixin, merchantMixin, iframeMixin],
    components: {BasketLayout, SidePanelHeader, Btn}, 
    data() {
        return {
            isLoading: false,
            offerValidated: false,
            orderRunning: false,
            bonusSelected: null,
            conditionAccepted: null
        }
    },
    watch: {
        readyToValidate() {

            this.validateOffer(this.basket.public_id)
        },
        isBasketLoaded(val) {

            if (val) {

                this.$tracking.pageView('Finalize Basket', 'Iframe Basket', {'Basket ID': this.basketId, 'Basket Product Type': this.basket.product_type, 'SKUS': this.$store.getters.skus })
            }
        }
    },
    methods: {
        async validateOffer(basketPublicId) {

            this.conditionAccepted = null
            const skusArray = this.$store.getters.skus == null || this.$store.getters.skus == undefined || this.$store.getters.skus.length == 0 ? [] : this.$store.getters.skus
            
            try {

                let response = await basketService.validateOffer(basketPublicId, skusArray)
                this.$store.commit('setBasket', response.data)
                this.offerValidated = true

                if (this.basketOffer.price == 0 && this.basket.bonus.length > 0) {

                    this.bonusSelected = this.basket.bonus[0]
                }
            }
            catch (error) {

                if (error.response.data.message == 'CONDITION_NOT_ACCEPTED') {

                    this.conditionAccepted = false
                }
            }

            this.bonusDisplayed()
        },
        async finalizeBasket() {

            let created_at_date = new Date(this.basket.created_at);
            created_at_date.setHours(created_at_date.getHours() + this.basket.expiration_hours);

            let expirationDate = created_at_date.getTime() / 1000;

            window.parent.postMessage(
                {
                    type: 'basket', 
                    productName: this.basketOffer.label,
                    amount: Math.round(this.basketOffer.price * 100),
                    publicId: this.basket.public_id,
                    expirationDate : expirationDate,
                    productImgUrl: this.basket.merchant_product_img,
                    finalizeBasketUrl: this.basket.finalize_order_url,
                    bonus: {
                        amount : this.bonusSelected ? this.bonusSelected.bonus_amount : null, 
                        name : this.bonusSelected ? this.bonusSelected.name : null, 
                    },
                }, 
                '*'
            )
        },
        redirectTo(locationName) {

            this.$router.push({name: locationName})
        },
        closePanel() {

            window.parent.postMessage({type: 'close'}, '*')
        },
        bonusDisplayed() {

            let maxAmount = 0;
            let maxAmountBonuses = [];

            if (this.basket.bonus.length < 1 ) return; 
            if (this.basket.bonus.length === 1 ) {

                this.bonusSelected = this.basket.bonus[0]
            }
            if (this.basket.bonus.length > 1 ) {

                this.basket.bonus.forEach(bonus => {

                    if (bonus.bonus_amount > maxAmount) {

                        maxAmount = bonus.bonus_amount;
                        maxAmountBonuses = [bonus];
                    } 
                    else if (bonus.bonus_amount === maxAmount) {

                        maxAmountBonuses.push(bonus);
                    }
                });

                this.bonusSelected = maxAmountBonuses[Math.floor(Math.random() * maxAmountBonuses.length)];
            }
        }
    },
    computed: {

        isBestOfferLoaded() {

            return this.offerValidated == true && this.basket != null && this.basketOffer != null
        },
        readyToValidate() {

            return this.isBasketLoaded && this.isSkuLoaded
        },

        isBestOfferLoadedAndFilled() {

            return this.isBestOfferLoaded && this.basketOffer != undefined
        },
        productConditions() {

            return this.basket.product_conditions
        },
        basketConditions() {

            return this.basket.conditions != null ? JSON.parse(this.basket.conditions) : undefined
        },
        basketOffer() {

            let basketOffer = {label: '', price: 0, brand:'' }

            if (this.basket == undefined) return null

            if (this.basket.attached_to == 'specs') {

                basketOffer.label = this.basket.product_offer.reseller_product_label
                basketOffer.price = this.basket.product_offer.price

                return basketOffer
            }

            if (this.basket.attached_to == 'offer_template') {

                basketOffer.label = this.basket.offer_template.label
                basketOffer.price = this.basket.product_offer.price

                return basketOffer
            }

            basketOffer.label = this.basket.product_offer.sku
            basketOffer.price = this.basket.product_offer.price

            return basketOffer
        }
    },
    created() {

        this.$store.commit('setSkus', this.$route.query['skus[]'])
    }
}
</script>
<template>
    <basket-layout>
        <div v-if="basket && basketOffer.price > 0" id="layout">
            <side-panel-header :title="''" :closeFunction="finalizeBasket" />
            <div id="page-content" style="overflow-y: auto;">
                <div id="content-header">
                    <img id="content-header-img" src="/assets/img/euro.svg" alt="">
                    <h2>Meilleure offre du marché</h2>
                    <h3 id="content-header-offer" >Offre de reprise : <span id="content-header-price" >{{basketOffer.price | currency}}</span></h3>
                    <p id="content-header-title" >{{basketOffer.label}}</p>
                </div>
                <div v-if="bonusSelected" id="bonus-container">
                    <div id="left-part" ></div>
                    <div id="bonus-text">
                        <p><span id="bonus-price"> + {{bonusSelected.bonus_amount / 100}} € de bonus </span> via code promo pour l’achat d’un {{bonusSelected.name}}</p>
                    </div>
                </div>
                <div id="next-step-container">
                    <h3>Les prochaines étapes :</h3>
                    <div class="next-step-row" id="next-step-row-bold">
                        <img src="/assets/img/arrow-next.svg" class="next-step-icon" />
                        <p class="bold">Finaliser votre reprise lors de l’étape de paiement.</p>
                    </div>
                    <div class="next-step-row">
                        <img src="/assets/img/icon-package.svg" class="next-step-icon" />
                        <p>Bénéficier <span class="bold">immédiatement</span> de votre carte cadeau. </p>
                    </div>
                    <div class="next-step-row">
                        <img src="/assets/img/icon-truck.svg" class="next-step-icon" />
                        <p>Expédier votre produit <span class="bold">sous 5 jours</span>, les frais d’expédition sont offerts.</p>
                    </div>
                </div>
                <div id="btns-wrapper" >
                    <btn @click="finalizeBasket()" :text="'Continuer mon achat'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`" :isLoading="isLoading"/> 
                    <btn @click="redirectTo('SearchFormIframe')" :text="'Faire une nouvelle estimation'" :color="'black'" :backgroundColor="`var(--iframe-color-white)`" :isLoading="isLoading"/> 
                </div>
            </div>
        </div>
        <div v-else-if="orderRunning">
            <side-panel-header :title="''" />
            Une revente pour ce produit est actuellement en cours de processus./>
            <div id="btns-wrapper" >
                <btn @click="redirectTo('SearchFormIframe')" :text="'Faire une nouvelle estimation'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`" :isLoading="isLoading"/>
            </div>
        </div>
        <div v-else-if="basket && (basketOffer.price === 0 || conditionAccepted == false || basketOffer.price == undefined)">
            <side-panel-header :title="''" />
            <div id="not-found-section">
                <div id="not-found-content">
                    <img id="content-header-img" src="/assets/img/icon-info.svg" alt="">
                    <h2>Nous n’avons pas d’offres disponibles pour ces conditions de reprise</h2>
                    <div id="btns-wrapper" >
                        <btn @click="redirectTo('SearchFormIframe')" :text="'Faire une nouvelle estimation'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`" :isLoading="isLoading"/> 
                        <btn @click="closePanel" :text="'Retour au panier'" :color="'black'" :backgroundColor="`var(--iframe-color-white)`" :isLoading="isLoading"/> 
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <side-panel-header :title="''" />
            <div id="not-found-section">
                <div id="not-found-content">
                    <span class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                </div>
            </div>
        </div>        
    </basket-layout>
</template>


<style scoped>

p {
    font-weight: var(--iframe-font-weight-normal);
    font-size: var(--iframe-font-size-p);
    margin: 0;
}

h2 {

    font-size: 18px;
    font-weight: var(--iframe-font-weight-bold);
}

h3 {

    font-size: var(--iframe-font-size-h3);
    font-weight: var(--iframe-font-weight-bold);
    margin: 0;
}

#layout {
    height: 100vh;
    overflow: hidden;
}

#page-content {
    height: calc(100vh - 100px);
    padding:0 32px 32px 32px;
}

#not-found-section {
    height: calc(100vh - 100px);
    padding:0 32px 32px 32px;
}

#content-header-img {

    margin-bottom: 20px;
}

#content-header-title {

    color: var(--iframe-border-grey-dark);
}

#content-header-price {

    color: var(--iframe-color-price);
    font-size: var(--iframe-font-size-h2);
    font-weight: var(--iframe-font-weight-bold);
}

#content-header {
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top:30px;
}

#bonus-container {

    height:66px;
    border: 1px var(--iframe-border-grey-light) solid;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

#bonus-container #bonus-text {

    margin:10px;
}

#left-part {

    background-color: #0C7203;
    min-width: 4px;
    height: 100%;
    position: relative;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

#content-header-offer {
    margin-bottom:10px;
}

#next-step-container {
    margin:30px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.next-step-icon {
    min-width: 25px;
    min-height: 25px;
}

.next-step-row {
    display: flex;
    align-items: center;
    gap: 8px;
}

.bold {

    font-weight: var(--iframe-font-weight-bold);
    color: black
}

#btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin:30px 0px;
    width: 100%;
}

#not-found-section, #not-found-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#not-found-section{
    width: 100%;
    height: 85vh;
}

#bonus-price {
    color:var(--iframe-color-price);
    font-weight: var(--iframe-font-weight-bold);
}

</style>
