<script type="text/ecmascript-6">
import { mapActions } from 'vuex'
import * as Sentry from "@sentry/vue";
import merchantMixin from '@/mixins/merchant'
import faqMixin from '@/mixins/faq'
import mediaMixin from '@/mixins/media'
import authService from '@/services/auth'
import BasketLayout from '@/layouts/iframe/Basket.vue'
import SidePanelHeader from '@/components/iframe/SidePanelHeader.vue';

export default {
    mixins: [merchantMixin, faqMixin, mediaMixin],
    components: {BasketLayout, SidePanelHeader},
    data() {
        return {
            email: '',
            password: '',
            passwordConfirm: '',
            firstName: '',
            lastName: '',
            isLoading: false,
            expeditionCountry: 'france',
            wantsToRegister: true,
            isCGUAccepted: false,
            error: ''
        }
    },
    methods: {
        ...mapActions({
            signIn: 'signIn',
            register: 'register'
        }),
        async submitSignIn() {

            this.$tracking.logEvent('Click signin', {'Page Name': 'register', 'Page Category': 'basket'})
            this.isLoading = true
            this.error = ''

            try {

                await this.signIn({email: this.email, password: this.password, basketId: this.basketId, merchantId: this.merchant.id})

                this.$router.push({path: this.returnUrl, query: {isLogged: 'true'}})
            }
            catch (error) {

                this.error = 'Impossible de se connecter'
                this.$tracking.logEvent('SignIn Error', {'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
            }

            this.isLoading = false
        },
        async submitRegister() {

            this.$tracking.logEvent('Click register', {'Page Name': 'register', 'Page Category': 'basket'})
            this.error = ''

            if (this.expeditionCountry != 'france') {

                return
            }

            if (!this.isCGUAccepted) {

                this.error = 'Veuillez accepter les CGU'
                this.$tracking.logEvent('Register Error', {'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})

                return
            }

            if (this.password.length < 8) {

                this.error = 'Le mot de passe doit comporter 8 caractères ou plus'
                this.$tracking.logEvent('Register Error', {'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})

                return
            }

            if (this.password != this.passwordConfirm) {

                this.error = 'Vos mots de passe ne sont pas identiques'
                this.$tracking.logEvent('Register Error', {'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})

                return
            }

            this.isLoading = true

            try {
                
                await this.register({email: this.email, password: this.password, isCGUAccepted: this.isCGUAccepted, basketId: this.basketId, lastName: this.lastName, firstName: this.firstName, phoneNumber: this.phoneNumber, address: this.address})
                
                this.$router.push({path: this.returnUrl, query: {isLogged: 'true'}})
            }
            catch (error) {

                Sentry.captureException(error)
                if (error.response.status == 500) {

                    this.error = 'Un compte '+ this.merchantName +' existe déjà pour cet utilisateur ' + this.merchant.name + '.'
                    this.$tracking.logEvent('Register Error', {'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
                }
                else {
                    
                    let errors = error.response.data.errors
                    
                    if (Object.prototype.hasOwnProperty.call(errors, 'password') && errors.password.length > 0) {

                        this.error = 'Le mot de passe doit comporter 8 caractères ou plus'
                        this.$tracking.logEvent('Register Error', {'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
                    }
                    else if (Object.prototype.hasOwnProperty.call(errors, 'email') && errors.email.length > 0) {

                        this.error = 'L\'email n\'est pas valide'
                        this.$tracking.logEvent('Register Error', {'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
                    }
                    else if (Object.prototype.hasOwnProperty.call(errors, 'address.country')) {

                        this.error = 'Votre adresse doit être en France'
                        this.$tracking.logEvent('Register Error', {'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
                    }
                }
            }

            this.isLoading = false
        },
        async submitSSOAuth() {

            let response = await authService.ssoAuth(null, null, this.returnUrl, this.merchantIdForAuth, 'fnac', null)
            
            if (response.data) {
                
                window.location.href = response.data
            }
        },
        async bypassLogin() {

            let isLogged = 'false'
            try {

                await authService.bypassLogin(this.email, this.merchantIdForAuth)
                isLogged = 'true'
            }
            catch (error) {

                isLogged = 'false'
            }
            
            window.location.href = this.returnUrl + '?isLogged=' + isLogged        
        },
        toggleRegister(wantsToRegister) {

            this.wantsToRegister = wantsToRegister
        },
        togglePasswordVisibility(element) {

            if (element.type == 'password')
                element.type = 'text'
            else
                element.type = 'password'
        },
    },
    created() {

        this.loadMerchantBySubdomain()

        if (this.hasSSO && this.isProduction)
            this.submitSSOAuth()
    },
    computed: {
        basketId() {
            const re = /\/basket\/(basket_[A-Z0-9]+)/i

            let arr = re.exec(this.returnUrl)
            if (arr == undefined || arr.length < 2) {

                return ''
            }

            return arr[1]
        },
        hasSSO() {

            return this.merchant && this.merchant.has_sso
        },
        isProduction() {

            return process.env.VUE_APP_ENVIRONMENT == 'production'
        },
        returnUrl() {

            return this.$route.query.returnUrl
        },
        accountName() {

            return this.merchant ? this.merchant.name : ''
        }   
    }
}
</script>
<template>
    <basket-layout>
        <side-panel-header :title="''" />
        <div v-if="merchant && hasSSO && !isProduction" class="mt-1 d-flex align-items-center flex-column">
            <h1>Login</h1>
            <div class="alert alert-danger text-center m-2">Only available in dev and test env<br/>In production, an automatic redirection is made to Fnac SSO</div>
            <button class="btn btn-info mt-3" @click="submitSSOAuth">Go to Fnac SSO</button>
            <span class="mt-3">OR</span>
            <input type="text" class="mt-3" placeholder="email" v-model="email" />
            <button class="btn btn-success mt-1" @click="bypassLogin">Bypass SSO</button>
        </div>
        <div v-if="merchant && !hasSSO">
            <ul class="nav nav-tabs justify-content-center mt-2" role="tablist">
                <li class="nav-item text-center col-6">
                    <a class="nav-link px-0" :class="{active: wantsToRegister}" data-toggle="tab" @click="toggleRegister(true)" style="cursor:pointer;" role="tab">Je crée un compte<br/><strong>{{ accountName }}</strong></a>
                </li>
                <li class="nav-item text-center col-6">
                    <a class="nav-link px-0" :class="{active: !wantsToRegister}" data-toggle="tab" @click="toggleRegister(false)" style="cursor:pointer;" role="tab">J'ai déjà un compte<br/><strong>{{ accountName }}</strong></a>
                </li>
            </ul>
            <div v-if="error" class="alert alert-danger" role="alert">
                {{error}}
            </div>
            <div class="tab-content">
                <div class="tab-pane" :class="{active: wantsToRegister, show: wantsToRegister, fade: !wantsToRegister}" id="signup" role="tabpanel">
                    <form action="#" @submit.prevent="submitRegister">
                        <div class="ms-3 mt-4">
                            <strong>Mon compte</strong>
                        </div>
                        <div class="form-group mt-2">
                            <input class="w-100 form-control" id="email" required type="text" v-model="email" placeholder="Email">
                        </div>
                        <div class="form-group mt-3">
                            <div class="input-group">
                                <input ref="password1" class="form-control" required type="password" v-model="password" placeholder="Mot de passe">
                                <div class="input-group-addon"><button tabindex="-1" @click="togglePasswordVisibility($refs.password1)" type="button" class="btn btn-icon"><i class="ci-eye" /></button></div>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <div class="input-group">
                                <input ref="password2" class="form-control" required type="password" v-model="passwordConfirm" placeholder="Confirmation du mot de passe">
                                <div class="input-group-addon"><button tabindex="-1" @click="togglePasswordVisibility($refs.password2)" type="button" class="btn btn-icon"><i class="ci-eye" /></button></div>
                            </div>
                        </div>
                        <div class="ms-3 mt-5">
                            <strong>Mes coordonnées</strong>
                        </div>
                        <div class="form-group mt-2 row">
                            <div class="col"><input class="form-control" required type="text" v-model="firstName" placeholder="Prénom"></div>
                            <div class="col"><input class="form-control" required type="text" v-model="lastName" placeholder="Nom"></div>
                        </div>
                        <div class="form-group mt-3">
                            J'expédie mon produit depuis ce pays : 
                            <select style="" class="form-control" v-model="expeditionCountry"><option value="france">France métropolitaine</option><option value="autre">Autre</option></select>
                            <div class="alert alert-danger mt-2" v-if="expeditionCountry != 'france'">
                                Pour finaliser votre revente depuis un autre pays que la France, merci de nous contacter par mail : <a :href="'mailto:' + getContactEmail()">{{ getContactEmail() }}</a>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <div class="form-check">
                                <input class="form-check-input border-success-lg" type="checkbox" name="input-cgu" v-model="isCGUAccepted">
                                <label class="form-check-label">J'accepte les <a :href="getCguLink(subdomain)" target="_blank">Conditions Générales d'Utilisation</a></label>
                            </div>
                        </div>
                            <div v-if="error" class="alert alert-danger" role="alert">
                            {{error}}
                        </div>
                        <div class="form-group mt-3">
                            <button :disabled="isLoading" class="btn btn-success w-100" type="submit">
                                Créer mon compte
                                <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="tab-pane" :class="{active: !wantsToRegister, show: !wantsToRegister, fade: wantsToRegister}" id="signin" role="tabpanel">
                    <form action="#" @submit.prevent="submitSignIn">
                        <div class="form-group mt-3">
                            <input required type="text" v-model="email" placeholder="Email" class="form-control w-100">
                        </div>
                        <div class="form-group mt-3">
                            <input required type="password" v-model="password" placeholder="Mot de passe" class="form-control w-100">
                        </div>
                        <div class="form-group mt-3">
                            <button :disabled="isLoading" class="btn btn-success w-100" type="submit">
                                Se connecter
                                <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                        <div class="form-group text-center mt-3">
                            <router-link :to="{name: 'forgotPassword'}">J'ai oublié mon mot de passe</router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-if="merchant && hasSSO && isProduction" class="d-flex justify-content-center">
            <button @click="submitSSOAuth" class="btn btn-dark">Se connecter</button>
        </div>
    </basket-layout>
</template>
