<script type="text/ecmascript-6">
export default {
    props: {
        'price': {
            'required': true
        },
        'isSelected': {
            'type': Boolean, 
            'default': false
        }
    },
    methods: {

        selectVouch() {

            if (this.$refs.inputCash)
                this.$refs.inputCash.checked = true
            this.$emit('vouchSelected', { price: this.price, campaign: null, type: 'cash' })
        }
    },
    created() {

        if (this.isSelected) {

            this.selectVouch();
        }
    }
}
</script>

<template>
    <div class="d-flex flex-row mt-4 ms-3" @click="selectVouch">
        <div class="card shadow-lg rounded-0 mx-0 col-md-7 col">
            <div class="row mx-0 h-100">
                <div class="col-1 px-0 row">
                    <div class="col-7 bg-info px-0"></div>
                    <div class="col-5 px-0"></div>
                </div>
                <div class="col-2 d-flex justify-content-center align-items-center px-0">
                    <input class="form-check-input" type="radio" id="cash" ref="inputCash" name="campaign" :checked="isSelected">
                </div>
                <div class="card-body col-9">
                    <p class="mb-1">EN CASH<br/><strong>PAR VIREMENT</strong></p>
                    <h2 class="text-info"><strong>{{price | currency}}</strong></h2>
                    <p class="font-size-xxs mt-3 me-3 mb-0">
                        Sous réserve de conformité de votre produit avec votre déclaratif. Une pièce d'identité et un IBAN valides devront être fournis. Argent disponible sous 14 jours après validation.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>