<script type="text/ecmascript-6">
export default {

    data() {
        return {

            iframeUrl: '',
            objectId: ''
        }
    },
    mounted() {

        this.iframeUrl = this.iframeUrlBase + '/searchForm' + this.queryString 

        window.addEventListener(
            "message",
            (event) => {
                console.log(event.data)
            },
            false
        )
    },
    methods: {
        changeIframeUrl(path) {

            console.log('changeIframeUrl')
            this.iframeUrl = this.iframeUrlBase + path.replace('<ID>', this.objectId) + this.queryString
        }
    },
    computed: {
        iframeUrlBase() {

            if (process.env.VUE_APP_ENVIRONMENT == 'production') return ''

            return window.location.origin + '/iframe'
        },
        queryString() {

            return window.location.search
        }
    } 
}
</script>
<template>
    <div class="row">
        <div class="col-6">
            <iframe class="iframe-test" :src="iframeUrl" scrolling="yes"></iframe>
        </div>
        <div class="col-6">
            <div class="mt-5">
                <input type="text" placeholder="Basket/Order ID" v-model="objectId" />
                <button @click="changeIframeUrl('/basket/<ID>/finalizeBasket')" class="btn btn-info ms-2">Finalize basket</button>
                <button @click="changeIframeUrl('/basket/<ID>/finalizeOrder')" class="btn btn-info ms-2">Finalize order</button>
                <button @click="changeIframeUrl('/order/<ID>/cardForm')" class="btn btn-info ms-2">Card form</button>
                <button @click="changeIframeUrl('/order/<ID>/kyc')" class="btn btn-info ms-2">Kyc</button>
                <button @click="changeIframeUrl('/order/<ID>/validated')" class="btn btn-info ms-2">Validated</button>
            </div>
        </div>
    </div>
</template>
<style scoped>
.iframe-test {
    width: 420px; 
    height: 100vh;
}
</style>
