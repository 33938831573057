<script type="text/ecmascript-6">

import VouchCash from '@/components/basket/VouchCash.vue'
import VouchBonus from '@/components/basket/VouchBonus.vue'
import Vouch from '@/components/basket/Vouch.vue'

export default {
    components: {Vouch, VouchBonus, VouchCash},
    props: {
        'merchant': {
            'type': Object,
            'required': true
        },
        'bonus': {
            'type': Array,
            'required': true
        },
        'offer': {
            'type': Object,
            'required': true
        },
    },
    methods: {
        vouchSelected(selectedVouch) {

            this.$emit('vouchSelected', selectedVouch)
        },
        updateCampaign(campaign) {

            this.$emit('updateCampaign', campaign)
        },
        isDefaultSelected(type) {

            if (type == 'coupon' && this.hasCoupon && !this.hasBonus) return true;

            if (this.hasCoupon) return false;

            if (type == 'cash' && this.hasCash) return true;

            return false;
        }
    },
    computed: {
        hasCash() {

            if (this.offer == undefined) return false

            if (this.offer.price <= 0) return false

            if (this.merchant == undefined) return false

            if (this.merchant.has_cash == false) return false

            return true
        },
        hasCoupon() {

            if (this.offer == undefined) return false

            if (this.offer.price <= 0) return false

            if (this.merchant == undefined) return false

            if (this.merchant.coupon_type == undefined) return false

            return true
        },
        hasBonus() {

            if (this.bonus == undefined) return false

            if (this.bonus.length == 0) return false

            return true;
        },
        cashPrice() {

            if (this.offer.price_raw != undefined) return this.offer.price_raw

            return this.offer.price
        },
        iphone16Campaign() {

            return this.bonus.find(campaign => campaign.public_id == 'brand_campaign_GRNDIPHONE16')
        },
        sortedBonus() {

            return this.bonus.toSorted((a, b) => {
                
                if (a.public_id === 'brand_campaign_FNACIPHONE16') return -1;
                if (b.public_id === 'brand_campaign_FNACIPHONE16') return 1;
                
                return 0;
            })
        }
    }
}
</script>

<template>
    <div>
        <div class="pointer">
            <vouch-bonus @vouchSelected="vouchSelected"
                :maxBestOffer="offer"
                :bonus="iphone16Campaign" 
            />
        </div>
        <vouch class="pointer" v-if="hasCoupon" @vouchSelected="vouchSelected"
            :maxBestOffer="offer" 
            :merchantData="merchant"
            :hasBonus="hasBonus"
            :isSelected="isDefaultSelected('coupon')"
        />  
        <div class="pointer" v-for="campaign in sortedBonus" :key="campaign.public_id">
            <vouch-bonus v-if="campaign.public_id != 'brand_campaign_GRNDIPHONE16'" @vouchSelected="vouchSelected"
                :maxBestOffer="offer"
                :bonus="campaign" 
            />
        </div>
        <vouch-cash class="pointer" v-if="hasCash" @vouchSelected="vouchSelected"
            :price="cashPrice"
            :hasBonus="hasBonus" 
            :couponValidityPeriod="merchant.coupon_validity_period" 
            :couponValidityProducts="merchant.coupon_validity_products" 
            :couponValidityPlaces="merchant.coupon_validity_places"
            :isSelected="isDefaultSelected('cash')" 
        />
    </div>
</template>


<style scoped>

.pointer {
    cursor: pointer;
}

</style>