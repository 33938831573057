<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/iframe/Basket.vue'
import SidePanelHeader from '../../components/iframe/SidePanelHeader.vue';
import categoryService from '@/services/category.js'
import offerTemplateService from '@/services/offerTemplate.js'
import basketService from '@/services/basket'
import mediaMixin from '@/mixins/media'
import ProductCard from '@/components/iframe/ProductCard.vue'
import merchantMixin from '@/mixins/merchant'
import Btn from '@/components/iframe/Button.vue'
import SelectAutoComplete from '@/components/basket/SelectAutoComplete.vue'
import * as Sentry from "@sentry/vue"

export default {

    components: {BasketLayout, SidePanelHeader, ProductCard, Btn, SelectAutoComplete},
    mixins: [mediaMixin, merchantMixin],
    data() {
        return {
            facets: [],
            categories: [],
            selectedCategory: null,
            selectedFacets : {
                category_id: null,
                displayed_brand: '',
                family: '',
                sub_family: '',
                model: '',
                option: '',
                capacity: '',
                processor_generation: '',
                gpu: '',
                hard_drive: '',
                ram: null,
                diagonal: null,
                processor_family: '',
                display_type: '',
                monitor_diagonal: '',
                monitor_screenType: '',
                monitor_max_resolution: '',
                monitor_connectors: '',
                monitor_curve_type: '',
                monitor_has_speakers: '',
            },
            initialSelectedFacets : {
                category_id: null,
                displayed_brand: '',
                family: '',
                sub_family: '',
                model: '',
                option: '',
                capacity: '',
                processor_generation: '',
                gpu: '',
                hard_drive: '',
                ram: null,
                diagonal: null,
                processor_family: '',
                display_type: '',
                monitor_diagonal: '',
                monitor_screenType: '',
                monitor_max_resolution: '',
                monitor_connectors: '',
                monitor_curve_type: '',
                monitor_has_speakers: '',
            },
            selectedItem: null,
            facetSelected:[],
            error: '',
            isLoading:false
        }
    },
    methods: {
        async loadCategories(merchantId) {

            let response = await categoryService.list(merchantId)

            this.categories = response.data

            if (this.$route.query.cid != undefined) {

                let cid = this.$route.query.cid
                let cat = this.categories.find(category => category.id == cid)
                if (cat != undefined) {

                    this.selectedCategory = cat
                }
            }
        },
        async loadFacets(categoryName = null, selectableValues = null) {

            if (!(categoryName == null || selectableValues == null)) {

                this.saveCategoryValues(categoryName, selectableValues)
            }

            let response = await offerTemplateService.listFacets(this.selectedFacets)
            
            this.facets = this.sortFacets(response.data.facets)

            this.selectedItem = response.data.hit
        },
        resetSearchForm () {

            this.selectedItem = null
            this.error = ''
            
            if (this.isSmartphone) {

                this.$refs.selectAutoComplete.resetSearchInput()
            }
        
            this.selectedFacets = this.initialSelectedFacets
            this.selectedCategory = null
            this.selectedFacets.displayed_brand = '' 
            this.selectedFacets.category_id = ''
            this.error = ''
            this.loadFacets()
        },
        sortFacets(facets) {

            for (const property in this.sorts) {

                const sorts = this.sorts
                
                facets[property].sort(function(a, b) {

                    const indexA = sorts[property].indexOf(a)
                    const indexB = sorts[property].indexOf(b)

                    if (indexA === -1 && indexB === -1) {
                        
                        return String(a).toLowerCase().localeCompare(String(b).toLowerCase())
                    }
                    
                    return indexB - indexA
                })
            }

            return facets
        },
        isFacetSetted(facet) {

            return this.selectedFacets[facet] != undefined && this.selectedFacets[facet] != ''
        },
        formatValue(val, selectedFacet) {

            if (selectedFacet === 'ram') {

                return val + ' Go'
            }

            if (selectedFacet === 'diagonal') {

                return val.substring(0, val.indexOf('.')) + '"'
            }

            return val
        },
        init() {

            this.isLoading = true
            this.setMerchantUserId(this.merchant, this.merchantUserId)
            
            if (this.merchantUserId) {
                
                this.loadCategories(this.merchant.id)
                this.loadFacets()
            }

            this.isLoading = false
        },
        async validate() {

            this.isLoading = true

            let id = this.selectedItem.id
            let oid = null
            let pid = null
            let sid = null

            if (id.startsWith('offer_template')) {

                oid = id.replace('offer_template_', '')
            }
            else if (id.startsWith('product')) {

                pid = id.replace('product_', '')
            }
            else if (id.startsWith('specs')) {

                sid = id
            }

            try {

                let response = await basketService.createFromSearchForm(this.merchant.id, this.merchantUserId, pid, oid, sid, this.selectedItemImg, this.source, 'iframe')

                let data = response.data

                if (data.type === 'basket') {

                    this.$router.push({name: 'basketInfoIframe', params: {id: data.basketId}})
                }
                else if (data.type === 'order') {

                    this.$router.push({name: 'validatedIframe', params: {id: data.orderId}})
                }
                else {

                    this.error = 'Une erreur est survenue avec ce produit'
                }
                
            }
            catch (error) {

                this.error = 'Une erreur est survenue avec ce produit'
            }

            this.isLoading = false
        },
        selectCategory(category) {

            this.selectedCategory = category
        },
        saveCategoryValues(categoryName, selectableValues) {

            let saveObject = []

            if (categoryName in this.facetSelected) {

                Object.entries(this.selectedFacets).forEach(([key, value])=> {

                    if (!(value === null || value ==='')) {
                        
                        saveObject[key] = value
                    }
                })

                let keys = Object.keys(this.facetSelected);
                let startIndex = keys.indexOf(categoryName);

                if (startIndex !== -1) {
                    let toRemove = keys.slice(startIndex + 1);
                    toRemove.forEach(key => {
                        
                        delete this.facetSelected[key];
                        delete saveObject[key]
                    });
                }
                this.selectedFacets = { ...this.initialSelectedFacets };

                Object.entries(saveObject).forEach(([key, value])=> {

                    this.selectedFacets[key] = value
                })
            } 
            else {

                this.facetSelected[categoryName] = selectableValues;
            }
        },
        displayOptions(facet) {
        
            if (facet in this.facetSelected) {
                return this.facetSelected[facet]
            }
            else {
                return this.facets[facet]
            }
        },
        itemSelected(item) {

            this.error = ''
            this.selectedItem = item
        },
        testDartyMessage(type) {

            if (type === 'basket') {

                const currentDate = new Date()
                window.parent.postMessage({
                    type: 'basket', 
                    productName: 'iPhone 14',
                    amount: 60000,
                    publicId: 'basket_1234567',
                    expirationDate : currentDate.setDate(currentDate.getDate() + 7),
                    productImgUrl: 'https://image.darty.com/telephonie/telephone_mobile_seul/iphone/apple_iph14_128go_bk_s2209087203527A_002609019.jpg',
                    finalizeBasketUrl: '',
                    bonus: {
                        amount : 10000, 
                        name : 'Bonus iPhone', 
                    },
                }, '*')
            }
            else if (type === 'order') {

                const currentDate = new Date()
                window.parent.postMessage({
                    type: 'order', 
                    productName: 'iPhone 14',
                    amount: 60000,
                    publicId: 'order_1234567',
                    expirationDate : currentDate.setDate(currentDate.getDate() + 7),
                    productImgUrl: 'https://image.darty.com/telephonie/telephone_mobile_seul/iphone/apple_iph14_128go_bk_s2209087203527A_002609019.jpg',
                    coupons: [{randomNumber: 'qskdlqksloieoi', pinCode: 'kdjkdjfkdjfkdjkfj'}, {randomNumber: 'qskdlqksloieoi', pinCode: 'kdjkdjfkdjfkdjkfj'}],
                    bonusCode: 'osidjk,iduiuzi',
                }, '*')
            }
            else if (type === 'close') {

                window.parent.postMessage({type: 'close'}, '*')
            }
        }
    },
    computed: {
        filteredFacets() {

            var filteredFacets = [
                'displayed_brand',
                'display_type',
                'diagonal',
                'processor_family',
                'processor_generation',
                'gpu',
                'hard_drive',
                'ram',
                'family',
                'sub_family',
                'model',
                'option',
                'capacity',
                'monitor_diagonal',
                'monitor_screen_type',
                'monitor_max_resolution',
                'monitor_connectors',
                'monitor_curve_type',
                'monitor_has_speakers'
            ]
            var toRemove = []
            if (this.facets != undefined) {

                let keys = Object.keys(this.facets)
                for (let i = 0; i < keys.length; i++) {

                    let facetName = keys[i]
                    if (this.facets[facetName].length <= 0) {

                        toRemove.push(facetName)
                    }
                }
            }

            filteredFacets = filteredFacets.filter(function(element) {

                return toRemove.includes(element) === false
            })

            let selectedFacets = this.selectedFacets
            filteredFacets = filteredFacets.filter(function(element, index) {

                
                if (index > 0) {

                    let prevFacet = filteredFacets[index - 1]
                    if (selectedFacets[prevFacet] == null || selectedFacets[prevFacet] == '') {

                        return false
                    }
                }

                return true
            })

            return filteredFacets
        },
        selectedItemImg() {

            if (this.selectedItem == undefined) return null

            if (this.selectedItem.img_url != undefined) return this.selectedItem.img_url

            return this.getCategoryAssetGeneric(this.selectedItem.category_id, this.selectedItem.displayed_brand)
        },
        selectedItemType() {

            if (this.selectedItem == undefined) return null

            let id = this.selectedItem.id

            if (id.startsWith('product')) return 'product'

            if (id.startsWith('offer_template')) return 'offer_template'

            if (id.startsWith('specs')) return 'specs'

            return null
        },
        syncCategories() {

            let categories = []
            if (this.categories.length > 0) {

                categories = this.categories.filter(category => category.is_only_async === 0)
            }
            
            return categories
        },
        sortedCategories() {

            const sorted = [
                'Ordinateurs portables',
                'Smartphones',
                'Consoles de jeux',
                'Tablettes',
                'Ecrans',
                'Cartes graphiques',
                'Ordinateurs fixes',
                'Montres connectées',
                'Casques VR',
                'Appareils photo',
                'Optiques photo',
                'Caméras - camescopes',
                'Vidéo projecteurs',
                'Lecteurs DVD',
                'Baladeurs',
                'Casques audio - écouteurs',
                'Enceintes'
            ]

            return this.syncCategories.slice().sort(function(a, b) {

                let indexA = sorted.findIndex((element) => element == a.name)
                indexA = indexA < 0 ? 10000 : indexA

                let indexB = sorted.findIndex((element) => element == b.name)
                indexB = indexB < 0 ? 10000 : indexB

                if (indexA > indexB) return 1
                if (indexB > indexA) return -1

                return 0
            })
        },
        merchantUserId() {

            return this.$store.getters.merchantUserId
        },
        isSmartphone() {

            return this.selectedFacets.category_id === 3
        },
        displayTestButtons() {

            return window.location.hostname.includes('darty') && process.env.VUE_APP_ENVIRONMENT != 'production'
        }
    },
    watch: {
        selectedCategory(val) {

            if (val != undefined) {

                this.selectedFacets.category_id = val.id
                this.loadFacets('category_id', val)
            }
        },
    },
    created() {

        this.$store.commit('setBasket', null)
        this.$store.commit('setMerchantUserId', this.$route.query.umid)
        this.$store.commit('setSkus', this.$route.query['skus[]'])

        this.loadMerchantBySubdomain()
        .then(() => {

            if (this.isMerchantLoaded === true)
                this.init()
        })  
        .catch((error) => {

            this.error = 'Une erreur est survenue.'
            Sentry.captureException(error);
        })
        
        this.$tracking.pageView('Search Form', 'Iframe Basket', {'Merchant User ID': this.$route.query.umid, 'Category ID': this.$route.query.cid, 'SKUS': this.$store.getters.skus})
    }
}
</script>
<template>
    <basket-layout>
        <div id="layout">
            <side-panel-header  />
            <div id="search-form">
                <div class="questions-container">
                    <span class="questions-title">{{$t('SEARCH_FORM.QUESTIONS.category.question')}}</span>
                    <div v-if="categories.length > 0" class="custom-select">
                        <select v-model="selectedCategory" class="select-box">
                            <option value="" disabled selected hidden>Catégorie</option>
                            <option @click="selectCategory(category)" :key="category.id" :value="category" v-for="category in syncCategories" placeholder="Catégorie">
                                {{ category.name }}
                            </option>
                        </select>
                        <span class="arrow-down"></span>
                        <span v-if="!selectedCategory" class="placeholder">
                            <span class="placeholder-text">{{$t('SEARCH_FORM.QUESTIONS.category.label')}}</span>
                        </span>
                    </div>
                </div>
                <div v-if="selectedCategory">
                    <div v-if="!isSmartphone">
                        <div class="questions-container" v-for="selectedFacet in filteredFacets" :key="selectedFacet">
                            <span class="questions-title">{{$t('SEARCH_FORM.QUESTIONS.'+ selectedFacet+'.question')}}</span>
                            <div class="custom-select">
                                <select  @change="loadFacets(selectedFacet, facets[selectedFacet])" v-model="selectedFacets[selectedFacet]" class="select-box">
                                    <option value="" disabled selected hidden></option>
                                    <option  :value="val" v-for="val in displayOptions(selectedFacet)" :key="val">
                                        {{formatValue(val, selectedFacet)}}
                                    </option>
                                </select>
                                <span class="arrow-down"></span>
                                <span v-if="selectedCategory && !selectedFacets[selectedFacet]" class="placeholder">
                                    <span class="placeholder-text">{{$t('SEARCH_FORM.QUESTIONS.'+ selectedFacet+'.label')}}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <select-auto-complete v-if="isSmartphone" 
                        ref="selectAutoComplete"
                        id="selectAutoComplete"
                        :categoryId="3"
                        @itemSelected="itemSelected"
                    />
                    <div @click="resetSearchForm">
                        <btn :text="'Effacer le formulaire'" :color="'black'" :backgroundColor="`var(--iframe-color-white)`" :isLoading="isLoading" /> 
                    </div>
                </div>
                <div @click="validate()" id="btn-validate-container" v-if="selectedItem && error == ''">
                    <btn :text="'Passer à l\'état du produit'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`" :isLoading="isLoading" /> 
                </div>
                <div class="mt-2" v-if="error != ''">
                    <p id="error-message" >{{ error }}</p>
                </div>
                <div v-if="selectedItem">
                    <product-card :brand="selectedFacets.displayed_brand" :description="selectedItem.label" :img="selectedItemImg"/>
                </div>
                <div v-if="displayTestButtons" class="mt-5 border border-dark p-2">
                    <h2>Only for tests</h2>
                    <button class="btn btn-info" @click="testDartyMessage('basket')">Exemple de message basket</button>
                    <button class="btn btn-info mt-2" @click="testDartyMessage('order')">Exemple de message order</button>
                    <button class="btn btn-info mt-2" @click="testDartyMessage('close')">Exemple de message close</button>
                </div>
            </div>
        </div>
    </basket-layout>
</template>
<style scoped>
* {
    font-family:var(--iframe-font-family);
}

#layout {
    height: 100vh;
    overflow: hidden;
}

#search-form {
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding:0 32px 32px 32px;
    scrollbar-color: var(--iframe-border-grey-normal);
    scrollbar-width: thin;
}

.custom-select {
    position: relative;
    display: flex;
    align-items: center;   
}

.custom-select select {
    width: 100%; 
    padding: 10px;
    font-size: 14px;
    border: 1px solid black;
    border-radius: 4px; 
    color: var(--iframe-color-black);
    appearance: none;
    cursor: pointer;
}

.arrow-down {
    width: 12px;
    height: 12px;
    z-index: 10;
    position: absolute;
    top:26px;
    right:20px;
    transform: rotate(45deg);
    display: inline-block;
    border-bottom: 2px var(--iframe-color-black) solid;
    border-right: 2px var(--iframe-color-black) solid;
}

#error-message {
    color:red;
}

.select-box {
    height: 56px;
    width: 100%;
    margin: 10px 0;
    border-radius: 4px;
}

.questions-title {
    font-weight: var(--iframe-font-weight-bold);
}

.questions-container {
    margin-bottom: 20px;
}

.placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
    color: white;
    pointer-events: none;
}

.placeholder-text {
    color: var(--iframe-color-black);
    font-size: var(--iframe-font-size-p);
}

#btn-validate-container {
    margin: 20px 0;
}
</style>
