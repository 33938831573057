<script type="text/ecmascript-6">

import Media from '../../mixins/media.js'
import Faq from '@/mixins/faq'
import Breadcrumbs from './BreadcrumbsV2.vue'
import merchantMixin from '@/mixins/merchant.js'

export default {
    props: {
        withBreadCrumbs: {

            type: Boolean,
            default: true
        }
    },
    components: {Breadcrumbs},
    mixins: [Media, Faq, merchantMixin],
    computed: {

        logoSrc() {

            if (this.merchant !== null)
                return this.getMerchantAsset(this.merchant.id, this.merchant.asset_logo_name)
            
            return null
        },
        currentStep() {

            return this.$store.getters.currentStep
        },
        merchantId() {

            if (this.$store.getters.merchant !== null)
                return this.$store.getters.merchant.id

            return null
        }
    },
}

</script>
<template>
    <header v-if="merchant != null" :id="merchantCss+'-header'" class="navbar navbar-expand-lg shadow-lg">
        <div class="row w-100" id="header-container">
            <div class="col text-center m-auto" id="left-section">
                <router-link :to="{name: 'searchForm'}" v-if="merchant.orders_history_url === 'greendid'" class="mx-md-0">
                    <img class="img" :src="logoSrc" />
                </router-link>
                <a v-else class="mx-md-0">
                    <img class="img" :src="logoSrc" />
                </a>
            </div>
            <div class="col mt-5" id="middle-section">
                <div v-if="withBreadCrumbs">
                    <breadcrumbs :currentStep="currentStep" :merchant="merchant" />
                </div>
            </div>
            <div id="right-section" class="col">
                <a class="navbar-brand" :id="merchantCss+'-logo'">
                    <img src="/assets/img/logo-greendid-2.png" class="img-fluid no-logo" />
                </a>
                <div id="contact-section" class="mt-3 mt-md-0">
                    <a :href="getFAQURL()" target="_blank" class="link d-flex align-items-center">
                        <i class="bi bi-question-circle mt-1 text-success"></i> 
                        <u class="font-size-xxs ms-1"><span>Centre d'aide</span></u>
                    </a>
                    <div v-if="merchant.white_label_domain == null" class="d-flex align-items-center">
                        <i class="bi bi-telephone mt-1 text-success"></i> 
                        <u class="font-size-xxs ms-1 mt-1">07 56 87 69 45</u>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
