<script type="text/ecmascript-6">
export default {

    created() {

        document.body.style = 'background-color:white;overflow: hidden'
    }
}
</script>
<template>
    <div id="greendid-side-panel-iframe" class="p-2">
        <slot/>
    </div>
</template>
<style scoped>
h1, h2, h3, h4, h5, span {
    
    color:black;
    font-family:var(--iframe-font-family);
}
</style>