<script type="text/ecmascript-6">

import { mapActions } from 'vuex'
import AuthLayout from '../../layouts/Auth.vue'
import merchantMixin from '@/mixins/merchant'
import SsoGoogleButton from '@/components/basket/SsoGoogleButton.vue'
import authService from '@/services/auth'

export default {
    mixins: [merchantMixin],
    components: {AuthLayout, SsoGoogleButton},
    data() {
        return {

            error: false,
            email: '',
            password: '',
            isLoading:null
        }
    },
    methods: {
        ...mapActions({
                signIn: 'signIn',
            }),
        async submitSignIn() {

            try {

                await this.signIn({email: this.email, password: this.password, merchantId: this.merchantIdForAuth})

                let queryReturn = this.$route.query.return
                if (queryReturn == null)
                    this.$router.replace({name: 'home'})
                else
                    this.$router.replace(queryReturn)
            }
            catch (error) {

                this.error = 'Impossible de se connecter'
            }
        },
        async submitSSOAuth() {

            let response = await authService.ssoAuth(null, null, this.returnUrl, this.merchantIdForAuth, 'fnac', this.orderPublicId)

            if (response.data) {
                
                window.location.href = response.data
            }
        },
    },
    created() {

        this.isLoading = true

        if (this.$route.query.logged != null && Array.isArray(this.$route.query.logged)) {

            this.$route.query.logged = this.$route.query.logged[(this.$route.query.logged.length)-1]
        }

        if (this.$route.query.reason != null && Array.isArray(this.$route.query.reason)) {

            this.$route.query.reason = this.$route.query.reason[(this.$route.query.reason.length)-1]
        }

        if (this.$route.query.reason === "handleSsoFailed") {

            this.error = 'Un compte greendid existe déjà avec cette adresse mail'
        }

        if (this.$route.query.logged === 'true') {

            this.$router.replace(this.$route.query.return)
        }

        this.isLoading = false
    },
    computed: {

        isFnacSSO() {

            if (this.isMerchantLoaded == false) return false

            return this.merchant.id == 3919794380
        },
        returnUrl() {

            return window.location.origin + this.$route.query.return
        },
        orderPublicId() {

            if (this.$route.query.return == null || this.$route.query.return == '') return null

            let regexp = /order\/(.*)\/.*/
            let result = this.$route.query.return.match(regexp)
            
            if (result.length < 1) return null

            return result[1]
        }
    }
}
</script>
<template>
    <auth-layout>
        <div v-if="error" class="alert alert-primary" role="alert">
            {{error}}
        </div>
        <form v-if="!isFnacSSO" action="#" @submit.prevent="submitSignIn">
            <div class="form-group">
                <input required type="text" v-model="email" placeholder="Email" class="form-control w-100">
            </div>
            <div class="form-group mt-2">
                <input required type="password" v-model="password" placeholder="Mot de passe" class="form-control w-100">
            </div>
            <div class="form-group mt-2">
                <button class="btn btn-success w-100" type="submit">Se connecter</button>
            </div>
            <div class="form-group mt-2">
                <sso-google-button :merchantId="merchantIdForAuth"/>
            </div>
            <div class="form-group text-center mt-4">
                <router-link :to="{name: 'forgotPassword'}">J'ai oublié mon mot de passe</router-link>
            </div>
        </form>
        <div v-if="isFnacSSO" class="form-group mt-2">
            <button class="btn btn-fnac-reprise w-100" @click="submitSSOAuth" type="submit">Se connecter</button>
        </div>
    </auth-layout>
</template>
