<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/iframe/Basket.vue'
import SidePanelHeader from '../../components/iframe/SidePanelHeader.vue';
import basketMixin from '../../mixins/basket'
import basketService from '@/services/basket'
import merchantMixin from '@/mixins/merchant'
import Btn from '@/components/iframe/Button.vue'

export default {

    mixins: [basketMixin, merchantMixin],
    components: {BasketLayout, SidePanelHeader, Btn}, 
    data() {
        return {
            conditionsChecked: null,
            maxBestOffer:null,
            toggleHelp: false,
            toggleHow: false,
            basketCondition: [],
            error:null,
            alertConditions: [],
            isLoading:false,
            conditionsClicked: null
        }
    },
    created() {

        this.loadMaxBestOffer(this.basket)
    },
    watch: {

        basket() {

            this.$tracking.pageView('Basket', 'Iframe Basket', {'Basket ID': this.basketId, 'Basket Product Type': this.productType, 'SKUS': this.$store.getters.skus})

            this.loadMaxBestOffer(this.basket)
        }
    },
    computed: {

        canValidate() {

            if (this.basketConditions === undefined || this.basketConditions === null) return false;

            const conditionsObj = JSON.parse(this.basketConditions);

            const productConditions = Object.values(this.basket.product_conditions);

            const productConditionNames = productConditions.map(condition => condition.name);

            const basketConditionNames = Object.keys(conditionsObj);
            
            for (let i = 0; i < this.alertConditions.length; i++) {

                if (basketConditionNames.includes(this.alertConditions[i].name)) {

                    basketConditionNames.splice(i, 1)
                }
            }

            const allConditionsFilled = productConditionNames.every(conditionName => basketConditionNames.includes(conditionName));

            return allConditionsFilled;
        },
        isMaxBestOfferLoaded() {

            return this.maxBestOffer != null
        },
        productType() {

            return this.basket.product_type;
        },
        productConditions() {

            return this.basket.product_conditions
        },
        basketConditions() {

            return this.basket.conditions 
        },

        filteredConditions() {

            if (this.productConditions == undefined) return []

            if (this.listingMode == false) return this.productConditions

            let newConditions = {};
            let conditionsArray = [];
            let conditionElement = null;

            Object.entries(this.productConditions).forEach(element => {

                if (element[1].answer != undefined || element[1].name == 'IS_WORKING' || element[1].name == 'IS_COMPLETE' || element[1].name == 'CONDITION') {

                    if (element[1].name === 'CONDITION') {

                        conditionElement = element[1];
                    } 
                    else {

                        conditionsArray.push(element[1]);
                    }
                }
            })

            if (conditionElement) {
                
                newConditions[0] = conditionElement;
            }

            conditionsArray.forEach((item, idx) => {
                
                newConditions[idx + 1] = item;
            });

            return newConditions
        },
        attachedToLabel() {

            if (this.basket == null) return null;
            if (this.basket.offer_template != null) return this.basket.offer_template.label;
            if (this.basket.offer_specs != null) return this.basket;
            return null
        }
    },
    methods: {
        loadMaxBestOffer(basket) {
            
            if (this.basket != undefined && this.maxBestOffer == undefined) {
                
                basketService.getMaxBestOffer(basket.public_id)
                    .then(response => {

                        var infos = response.data
                        this.maxBestOffer = {price: infos.max_best_offer_price, currency: infos.max_best_offer_currency, maxBonus: infos.max_bonus_amount}
                    })

                this.basket.conditions = null
            }
        },
        validate() {

            this.isLoading = true
            this.$router.push({name: 'finalizeBasket', params: {id: this.basket.public_id}})
            this.isLoading = false
        },

        getProductCondition(conditionName) {

            return this.productConditions[conditionName] != undefined ? this.productConditions[conditionName] : undefined
        },
        getConditionLabel(val, productType) {

            let label = this.$t('BASKET_CONDITION_LABEL.' + productType + '.' + val)
            let index = label.indexOf(' - ')

            return label.substring(0, index)
        },
        getConditionLabelDesc(val, productType) {

            let stringToEscape = "- <br class='d-md-none'>"
            let label = this.$t('BASKET_CONDITION_LABEL.' + productType + '.' + val)
            let index = label.indexOf(stringToEscape)

            let restOfLabelDesc = label.substring(index + stringToEscape.length)

            return restOfLabelDesc.charAt(0).toUpperCase() + restOfLabelDesc.slice(1);
        },
        updateColor(index) {

            this.conditionsChecked = index
        },

        isBorderButton(conditionName, val) {
            
            const conditionsObj = this.conditionsClicked;

            if (conditionsObj && conditionsObj[conditionName] === val) {

                return `var(--iframe-color-orange)`; 
            }
    
            return `var(--iframe-border-grey-light)`;
        },
        handleBtnClick(conditionName, value) {

            this.conditionClicked(conditionName, value)
            this.isLoading = true
            this.updateCondition(conditionName, value)
            this.isLoading = false
        },
        updateCondition(conditionName, val) {
            
            if (conditionName === 'CONDITION') {

                this.conditionsChecked = val
            }
            
            let basketCondition =  ({name: conditionName, val: val, title: this.$t('BASKET_CONDITION.' + conditionName + '.' + this.productType + '.TITLE')})

            let condition = {}
            condition[conditionName] = basketCondition.val

            basketService.updateCondition(this.basket.public_id, condition)
                .then(response => {

                    if (response.data == 1) {

                        this.$store.commit('updateCondition', basketCondition)
                    }
                    else {

                        this.$router.push({name: 'error'})
                    }
                })
                .catch(error => {

                    if (error.response.status == 404) {

                        this.$router.push({name: 'error'})
                    }

                    if (error.response.status == 422) {

                        if (this.alertConditions.find(element => element.name === basketCondition.name) == undefined) {

                            this.alertConditions.push(basketCondition)
                        }
                    }
                })

            for (let i = 0; i < this.alertConditions.length; i++) {

                if (this.alertConditions[i].name === basketCondition.name) {

                    this.alertConditions.splice(i, 1)
                }
            }
        },
        getCondition(conditionName) {

            return Object.values(this.basket.product_conditions).find(element => element.name == conditionName)
        },
        conditionClicked(conditionName, val) {

            if (this.conditionsClicked === null) {

                this.conditionsClicked = {}
            }

            this.conditionsClicked[conditionName] = val
        },
        styleLabel(label) {

            return '<strong>' + label.replace('-', '</strong> - ')
        },
        getHelpText(contentType) {

            let helpText = this.$t('BASKET_CONDITION.IS_RESETED.' + this.productType + '.' + contentType)
            if (helpText != 'BASKET_CONDITION.IS_RESETED.' + this.productType + '.' + contentType) {

                return helpText
            }

            helpText = this.$t('BASKET_CONDITION.IS_PASSWORD_PROTECTED.' + this.productType + '.' + contentType)
            if (helpText != 'BASKET_CONDITION.IS_PASSWORD_PROTECTED.' + this.productType + '.' + contentType) {

                return helpText
            }

            return ''
        }
    }
}
</script>
<template>
    <basket-layout>
        <div v-if="this.basket" id="layout">
            <side-panel-header :title="'État de votre produit'" :backBtnDisplayed="true"/>
            <div id="page-content">
                <div id="conditions-form-container">
                    <div id="form-container" v-for="(condition, index) in filteredConditions" :key="index">
                        <h3 id="form-title" v-html="$t('BASKET_CONDITION.' + condition.name + '.' + productType + '.TITLE')"></h3>
                        <div id="condition-btns" v-if="condition.type == 'condition'">
                            <div @click="updateCondition('CONDITION', parseInt(index))" :class="{'container-checked' : conditionsChecked === index}" class="conditions-radio-button" :key="index" v-for="(condition, index) in $t('BASKET_CONDITION_LABEL.' + productType)">     
                                <div class="radio-button-bcg">
                                    <div :class="{'checked' : conditionsChecked == index}" class="radio-button"></div>
                                </div>
                                <div id="conditions-radio-button-text-container">
                                    <label class="conditions-radio-button-text" id="conditions-radio-button-text-title" :for="index" v-html="getConditionLabel(index, productType)" />
                                    <label class="conditions-radio-button-text" :for="index" v-html="getConditionLabelDesc(index, productType)" />
                                </div>
                            </div>
                        </div>
                        <div v-if="condition.type != 'condition'" id="form-btns">
                            <btn class="is-working-btn" @click="handleBtnClick(condition.name, true)" :text="'Oui'" :color="`var(--iframe-color-black)`" :backgroundColor="'white'" :border="isBorderButton(condition.name, true)" :isLoading="isLoading"/> 
                            <btn class="is-working-btn" @click="handleBtnClick(condition.name, false)" :text="'Non'" :color="`var(--iframe-color-black)`" :backgroundColor="'white'" :border="isBorderButton(condition.name, false)" :isLoading="isLoading"/> 
                        </div>
                    </div>
                    <div :key="alertCondition.name" v-for="alertCondition in alertConditions">
                        <p id="alert-condition" v-if="alertCondition" v-html="$t('BASKET_CONDITION.' + alertCondition.name + '.' + productType + '.ALERT')"></p>
                    </div>
                </div>
                <div id="help-container">
                    <div id="help-container-toggle-container" @click="toggleHelp = !toggleHelp">
                        <p id="help-container-toggle-container-text">Besoin d'aide ?</p>
                        <span :class="{'arrow-up': toggleHelp, 'arrow-down': !toggleHelp}" class="arrow"></span>
                    </div>
                    <div v-if="toggleHelp">
                        <p v-html="$t('BASKET_CONDITION.IS_WORKING.' + productType + '.DESCRIPTION')"></p>
                    </div>
                </div>
                <div id="how-container">
                    <div v-html="getHelpText('TITLE')" style="margin-top: 20px" :style="{fontWeight: `var(--iframe-font-weight-normal)`, fontSize: `var(--iframe-font-size-p)`, color: `var(--iframe-color-black)`}"></div>
                    <div id="how-container-toggle-container" class="mt-2" @click="toggleHow = !toggleHow">
                        <p id="how-container-toggle-container-text">Comment procéder ?</p>
                        <span :class="{'arrow-up': toggleHow, 'arrow-down': !toggleHow}" class="arrow"></span>
                    </div>
                    <div v-if="toggleHow" class="mb-2">
                        <p v-html="getHelpText('DESCRIPTION')"></p>
                    </div>
                </div>
                <div>
                    <span :style="{fontWeight: `var(--iframe-font-weight-bold)`, fontSize: `var(--iframe-font-size-p)`}">
                        Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.
                    </span>
                    <span :style="{fontWeight: `var(--iframe-font-weight-normal)`, fontSize: `var(--iframe-font-size-p)`}">
                        En cas de renvoi du produit, les frais de retour vous seront facturés.
                    </span>
                </div>
                
                <div @click="validate()" id="btn-validate-container">
                    <btn :text="'Passer à l\'estimation'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`" :disabled="canValidate" :isLoading="isLoading" /> 
                </div>
            </div>
        </div>
    </basket-layout>
</template>


<style scoped>

* {
    font-family:var(--iframe-font-family);
}

p {
    font-size:var(--iframe-font-size-p);
    margin:0;
}

h3 {
    font-size:var(--iframe-font-size-h3);
}

.conditions-radio-button input[type="radio"] {
  display: none;
}

.conditions-radio-button .radio-button-bcg{
  min-width: 20px;
  min-height: 20px;
  border: 1px solid var(--iframe-border-grey-normal);
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-content: center;
  justify-content: center;

}

.checked {
    background-color: var(--iframe-color-orange);
    border-color: var(--iframe-color-orange);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: auto;
}

.container-checked {

    border: 1px black solid;
}

#layout {
    height: 100vh;
    overflow: hidden;
}

#conditions-form-container, #is-working-form-container {
    width: 100%;
    margin: 40px 0;
}

.conditions-radio-button {
    border: 1px var(--iframe-border-grey-normal) solid;
    border-radius: 4px;
    height: fit-content;
    margin-top:16px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    z-index: 20;
}

.conditions-radio-button:hover {
    border: 1px var(--iframe-border-grey-dark) solid;
}

.conditions-radio-button-text {
    font-size: var(--iframe-font-size-h3);
    font-weight: var(--iframe-font-weight-normal);
    width: 100%;
    cursor: pointer
}

#conditions-radio-button-text-title {
    color: var(--iframe-color-black)
}

#conditions-radio-button-text-container {
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
}

#form-btns {

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
}

#condition-btns {
    
    margin-bottom: 30px;
}

#btn-validate-container {
    margin: 30px 0px;
}

.arrow {
    
    width: 8px;
    height: 8px;
    z-index: 10;
    display: inline-block;
    transform: rotate(45deg);
    margin-left:5px;
    margin-top:0px;
    margin-bottom:5px;
}

#help-container-toggle-container,  #how-container-toggle-container{
    cursor:pointer;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width:fit-content;
}

#help-container-toggle-container-text, #how-container-toggle-container-text { 
    
    color: var(--iframe-border-grey-dark);
    text-decoration-line: underline ;
}

.arrow-down {
    border-bottom: 2px var(--iframe-border-grey-dark) solid;
    border-right: 2px var(--iframe-border-grey-dark) solid;
}

.arrow-up {

    border-bottom: 'none';
    border-right: 'none';
    border-top: 2px var(--iframe-border-grey-dark) solid;
    border-left: 2px var(--iframe-border-grey-dark) solid;
    margin-top:5px;
    margin-bottom:0px;
}

#how-container {
    margin-top: 20px;
}

#alert-condition { 
    color: red;
    margin-bottom: 20px;
}
#page-content {
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding:0 32px 32px 32px;
    scrollbar-color: var(--iframe-border-grey-normal);
    scrollbar-width: thin;
}
</style>
