<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/iframe/Basket.vue'
import SidePanelHeader from '../../components/iframe/SidePanelHeader.vue';
import Btn from '@/components/iframe/Button.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'

import orderService from '../../services/order'
import VuePhoneNumberInput from 'vue-phone-number-input';
export default {

    components: {BasketLayout, SidePanelHeader, Btn, VuePhoneNumberInput}, 
    mixins: [merchantMixin, orderMixin],
    data() {
            return {
                    yousignMemberId: null,
                    phoneNumber: null,
                    phoneNumberValue: null,
                    isPhoneNumberValid: false,
                    isDisplayWaitMessage: false,
                    isLoading: false,
                    modalCancelOrder: false,
                    error: null,
                    yousignSignatureLink: null
                }
        },
    methods: {
        sleep(ms) {
            
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async makeTransfer() {
            
            if (this.phoneNumber == null) {
                
                this.error = 'Veuillez renseigner votre numéro de téléphone'
                return 
            }

            this.isLoading = true

            try {

                let response = await orderService.createTransfer(this.order.public_id, this.phoneNumber)

                if (response.data.version === '3') {

                    await this.sleep(2000) // Do that otherwise yousign can failed :(

                    this.yousignSignatureLink = response.data.signature_link
                    if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
                        
                        console.log('this.yousignSignatureLink', this.yousignSignatureLink)
                    }
                    this.initializeYouSign()
                    this.error = null
                }
                else if (response.data.version === '2') {

                    this.yousignMemberId = response.data.member_id
                }
            }
            catch (error) {

                this.error = 'Une erreur est survenue. Nous nous excusons pour ce problème';
                this.isLoading = false
            }
        },
        updatePhoneNumber(data) {

            this.phoneNumber = data.e164
            this.isPhoneNumberValid = data.isValid
        },
        async initializeYouSign() {

            const { default: Yousign } = await import('../../utils/Yousign.js');

            const yousign = new Yousign({
                signatureLink: this.yousignSignatureLink,
                iframeContainerId: 'iframe',
                isSandbox: process.env.VUE_APP_ENVIRONMENT === 'production' ? false : true,
            });

            if (yousign) {

                console.log('yousign ok');
            }

            yousign.onSignatureDone((data) => {
                this.handleSigned(data)
            });
        },
        closeYouSign() {

            const divHide = document.getElementById("yousign-iframe");
            divHide.remove()
            this.yousignSignatureLink = null;
        },
        async handleSigned() {

            await this.sleep(2000)
            this.validateSigned()
            
        },
        validateSigned() {

            this.$router.push({name:'validatedIframe'})
        }
    },
    computed: {
        phoneTranslations() {
            return {

                countrySelectorLabel: 'Code pays',
                countrySelectorError: 'Choisir un pays',
                phoneNumberLabel: 'Numéro de téléphone portable',
                example: 'Exemple :'
            }
        }
    },
    watch: {
        isOrderLoaded() {

            this.$tracking.pageView('Transfer', 'Iframe Order', {'Order ID': this.orderId})
        }
    },
    beforeRouteEnter(to, from, next) {

        next(vm => {

            if (from.name == 'validatedIframe') {

                vm.isDisplayWaitMessage = true
            }
        })
    },
}
</script>
<template>
    <basket-layout>
        <side-panel-header :title="'Bon de cession'"/>
        <div v-if="isOrderLoaded && !yousignSignatureLink" id="transfert-content">
            <div id="number-phone-input-container">
                <h3 class="transfer-content-title">Renseignez votre numéro de téléphone</h3>
                <p style="color:black">Vous recevrez un code de validation par sms</p>
                <div id="number-phone-input-row">
                    <div class="card-input-container">
                        <vue-phone-number-input id="number-phone-input" :translations="phoneTranslations" v-model="phoneNumberValue" @update="updatePhoneNumber" :preferred-countries="['FR', 'BE', 'DE']" :default-country-code="'FR'" />
                    </div>
                </div> 
            </div>
            <div @click="makeTransfer()" id="btn-validate-container">
                <btn :text="'Signer le bon de cession'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`"  :isLoading="isLoading || isDisplayWaitMessage"/> 
            </div>
            <div v-if="isDisplayWaitMessage">
                <span>Si vous avez déjà signé le bon de cession, cliquez pour continuer.</span>
                <div @click="validateSigned">
                    <btn :text="'Continuer'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`" />
                </div>
            </div>
            <p id="error-message" v-if="error" >{{ error }}</p>
        </div>
        <div v-else-if="yousignSignatureLink != null" style="height:400px" id="iframe"></div>
    </basket-layout>
</template>
<style scoped>

* {
    font-family: 'Roboto';
}

p {
    font-weight: var(--iframe-font-weight-normal);
    font-size: var(--iframe-font-size-p);
    margin: 0;
}

h2 {

    font-size: 18px;
    font-weight: var(--iframe-font-weight-bold);
}

h3 {

    font-size: var(--iframe-font-size-h3);

    margin: 0;
}


#layout {
    height: 100vh;
    overflow: hidden;
}

#transfert-content{
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding:0 32px 32px 32px;
}

#number-phone-input-container {
    margin-top:32px;
}

.transfer-content-title {
    font-weight: var(--iframe-font-weight-bold);
}

#number-phone-input-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap:24px;
    margin-top:16px;
}


#number-phone-input {
    border-bottom:1px solid var(--iframe-border-grey-normal);
    border-top:none;
    border-left:none;
    border-right:none;
    width: 100%;
    height: 40px;
}

.card-input-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-placeholder {
    position: absolute;
    top: 10px;
    left: 10px;
    color: var(--iframe-border-grey-dark);
}

.card-placeholder.active {
    display: none;
}

#btn-validate-container {
    margin:20px 0;
}

#error-message {
    color:red;
}

#iframe-container {
    display: flex;
    flex-direction: column;
    align-items: end;
    height: 100%;
}
</style>

