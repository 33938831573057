<script type="text/ecmascript-6">
import BasketLayout from '@/layouts/iframe/Basket.vue'
import SidePanelHeader from '@/components/iframe/SidePanelHeader.vue';
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import MangoCardForm from '@/components/basket/MangoCardForm.vue'

export default {

    components: {BasketLayout, SidePanelHeader, MangoCardForm},
    mixins: [orderMixin, merchantMixin],
    data() {
        return {
            error: '',
            mangoCardFormKey: 1,
            isLoading: false
        }
    },
    created() {

        this.$store.commit('setCurrentStep', 30)
    },
    methods: {
        handleError() {

            this.mangoCardFormKey = this.mangoCardFormKey + 1
            this.error = 'Une erreur est survenue. Veuillez réessayer ou contacter le support.'
            this.isLoading = false
        },
        handlePaymentComplete(status) {

            if (!this.isProduction) {

                console.log('handlePaymentComplete', status)
            }

            if (status == 'SUCCEEDED') {

                this.$router.push({name: 'kycIframe', params: {id: this.order.public_id}})
            }
            else {

                this.handleError()
            }
        }
    }, 
    watch: {
        isOrderLoaded() {

            this.$tracking.pageView('Mango Card Form', 'Iframe Order', {'Order ID': this.order.public_id, 'Deposit ID': this.$route.query.depositId, 'Status': this.$route.query.status})
        }
    },
    computed: {
        isProduction() {

            return process.env.VUE_APP_ENVIRONMENT === 'production'
        },
        amount() {
  
            if (this.order == undefined) return 0
            
            if (this.order.payment_type == 'cash') {

                return this.order.bonus_amount
            }

            return this.order.price + this.order.bonus_amount
        }
    }
}
</script>
<template>
    <basket-layout>
        <side-panel-header :title="'Pré-autorisation bancaire'" />
        <section v-if="isOrderLoaded">
            <div class="card mb-2 w-100">
                <div class="card-body">
                    <small>
                        Pré-autorisation non débitée : <strong>{{amount | currency}}</strong>
                        <span v-if="order.payment_type == 'coupon' && order.bonus_amount > 0"><br/>(comprenant le montant du bonus)</span>
                        <span v-if="order.payment_type == 'cash' && order.bonus_amount > 0"><br/>(montant du bonus)</span>
                    </small>
                </div>
            </div>
            <div v-if="error" class="mb-2 alert alert-danger"><small>{{ error }}</small></div>
            <MangoCardForm 
                v-if="isOrderLoaded"
                :key="mangoCardFormKey"
                @error="handleError" 
                @paymentComplete="handlePaymentComplete"
                :orderPublicId="this.order.public_id" 
                :amount="amount * 100" 
                @createCardRegistration="isLoading = true"
            />
        </section>
    </basket-layout>
</template>
